import React from "react";
import homebackground from "../Images/homebackground.png";
import homePotraitBackground from "../Images/homePotraitBackground.png";
import { Link } from "react-router-dom";


import "../Home/home.css";

function Home() {
  return (
    <div className="background-image">
      <img src={homePotraitBackground} alt="potrait position grid of images of motorcycle and colored rims" className="center-potrait"></img>
      <img src={homebackground} alt="grid of images of motorcycle and colored rims" className="center"></img>
<div class="card-img-overlay d-flex flex-row justify-content-center h-50 w-55 background-button">
        <button className="request-quote" data-elfsight-show-form="a801fb7d-2e21-4eae-a284-e53821a19326">Request a Quote</button>
        <div class="elfsight-app-608c3120-2323-4753-90c4-dc51c6364940"></div>
        <button className="services"><Link to="/price" style={{ textDecoration: "none", color: "white", fontFamily: "Oswald", fontSize: "1.5rem"}}>View Services</Link></button>
      </div>
    </div>
  )
}

export default Home;